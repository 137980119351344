import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OutletResignation: FunctionComponent<GraphAnalyticsCardProps<number[]>> = ({ labels, data, title }) => {
    const chartData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: data.map((value) => {
                    if (value < 4) return '#edcd77';
                    return '#e6765a';
                }),
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value: number) {
                    if (!value) return null;
                    return `${value.toFixed(2)}`;
                },
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Kündigungsquote in der Probezeit"
            subtitle={title}
            tooltip="Alle Kündigungen durch einen Mitarbeiter während der Probezeit "
        >
            <Bar
                data={chartData}
                options={chartOptions}
            />
        </AnalyticsCard>
    );
};

export default OutletResignation;
