import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LastOutletScore } from '@mywellness-gmbh/backend-types';
import { useOutletComparisonData } from '../context/KPIQuality/KPIOutletComparisonProvider';
import LoadSpinner from '../components/LoadSpinner';
import OutletCustomerFeedback from '../components/analytics/cards/OutletCustomerFeedback';
import TabSelector from '../components/TabSelector';
import { arrayAverage, getLastThreeMonths } from '../util';
import LastThreeAudits from '../components/analytics/cards/LastThreeAudits';
import ProductivityLastMonths from '../components/analytics/cards/ProductivityLastMonths';
import OutletResignation from '../components/analytics/cards/OutletResignation';
import { IOutletComparisonData } from '../types/kpiQuality/outletComparison';

ChartJS.register(LineElement, BarElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels);

const prepareSelectedData = (data: IOutletComparisonData, selected?: string[] | null): SelectedData => {
    if (!data.wasFetched)
        return {
            feedback: {},
            feedbackCount: 0,
            audit: [],
            productivity: [],
            resignation: [],
            months: [],
        };

    if (!selected || selected[0] === '' || selected[0] === 'Gesamt') {
        // loop through all outlets
        const totalFeedbacks = data.feedback.reduce<Record<string, Record<string, number>>>((acc, outlet) => {
            // for every feedback type
            Object.entries(outlet.feedbackScores).forEach(([type, scores]) => {
                // add the scores of the different months while keeping the types separated
                scores.forEach((score) => {
                    if (!acc[type]) {
                        acc[type] = {};
                    }
                    if (!acc[type][score.month]) {
                        acc[type][score.month] = 0;
                    }
                    acc[type][score.month] += score.value;
                });
            });
            return acc;
        }, {});

        // devide the total feedbacks by the number of outlets to get the average
        const feedback = Object.entries(totalFeedbacks).reduce<Record<string, { month: string; value: number }[]>>((acc, [type, count]) => {
            acc[type] = Object.entries(count).map(([month, value]) => ({ month, value: value / data.feedback.length }));
            return acc;
        }, {});

        const avgAudit = data.audits.map((month) => ({ rating: arrayAverage(month.map((a) => a.rating)) }));

        const months = getLastThreeMonths();

        const avgProductivity = Object.values(
            Object.values(data.productivity.data).reduce<Record<string, (number | null)[]>>((acc, outlet) => {
                outlet.forEach((month) => {
                    if (!acc[month.month]) {
                        acc[month.month] = [];
                    }
                    acc[month.month].push(month.value);
                });
                return acc;
            }, {}),
        ).map((values) => arrayAverage(values));

        const avgResignation = data.resignationInProbationaryByInterval.map((month) => arrayAverage(month.values.map((v) => v.value)));

        const totalFeedbackCount = data.feedbackCount.reduce((sum, count) => sum + count, 0);

        return {
            feedback,
            audit: avgAudit,
            productivity: avgProductivity,
            feedbackCount: totalFeedbackCount || 0,
            resignation: avgResignation,
            months,
        };
    }
    const feedback = data.feedback.filter((f) => f.displayName === selected[0])[0] || {};

    const audit = data.audits.flat().filter((a) => a.displayName === selected[0]);
    const productivity = (data.productivity.data[selected[0]] || []).map((p) => p.value || 0);
    const resignation = (data.resignationInProbationary.data[selected[0]] || []).map((r) => r.value || 0);

    const months = getLastThreeMonths();

    return {
        feedback: feedback?.feedbackScores,
        feedbackCount: feedback?.feedbackCount || 0,
        audit,
        productivity,
        resignation,
        months,
    };
};

const KPIOutletStatistics: FunctionComponent = () => {
    const { data, fetchData } = useOutletComparisonData();
    const [selected] = useSearchParams('selected');
    useEffect(() => {
        fetchData();
    }, []);
    const selectedData = useMemo(() => prepareSelectedData(data, selected.get('selected')?.split(',')), [data, selected]);
    if (!data.wasFetched) {
        return <LoadSpinner />;
    }

    return (
        <>
            <div className="col-span-full">
                <TabSelector />
            </div>
            <OutletCustomerFeedback
                data={selectedData.feedback}
                title="Kundenfeedback"
                subtitle={`${selectedData.feedbackCount || 0} Bewertungen`}
            />
            <LastThreeAudits
                data={selectedData.audit}
                labels={selectedData.months}
            />
            <ProductivityLastMonths
                data={selectedData.productivity}
                labels={selectedData.months}
            />
            <OutletResignation
                data={selectedData.resignation}
                labels={selectedData.months}
            />
        </>
    );
};

export default KPIOutletStatistics;

type SelectedData = {
    feedback: Record<string, { month: string; value: number }[]>;
    feedbackCount: number;
    audit: Partial<LastOutletScore>[];
    productivity: number[];
    resignation: number[];
    months: string[];
};
