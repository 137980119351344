import { FunctionComponent, useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { StoredAnalyticsMonthlyDataRecord } from '../../../types/kpiQuality/outletComparison';

const ResignationInProbationary: FunctionComponent<GraphAnalyticsCardProps<StoredAnalyticsMonthlyDataRecord>> = ({
    labels,
    data,
    fetchData,
}) => {
    const lastData = useMemo(() => data && Object.values(data).map((value) => value.slice(-1)[0]), [data]);
    const chartData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                data: lastData.map((value) => value.value),
                backgroundColor: lastData.map((value) => {
                    if (!value.value) return '#90AF76';
                    if (value.value < 4) return '#edcd77';
                    return '#e6765a';
                }),
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value: number) {
                    if (!value) return null;
                    return `${value.toFixed(2)}`;
                },
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Kündigungsquote in der Probezeit"
            subtitle="der Filialen"
            tooltip="Alle Kündigungen durch einen Mitarbeiter während der Probezeit "
            onSaveEditableConfig={fetchData && fetchData}
            editableConfigIdentifier="resignation_in_probationary"
            live
        >
            <Bar
                options={chartOptions}
                data={chartData}
            />
        </AnalyticsCard>
    );
};

export default ResignationInProbationary;
